import { css } from "styled-components";

const postTableOfContents = css`
  .table-of-contents {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    max-height: calc(100vh - 1.5rem - 340px);
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 40%);
    padding: 1.5rem;
  }

  .table-of-contents ul {
    flex: 1 1 auto;
    font-size: 0.9rem;
    line-height: 2;
    margin: 0;
  }

  .table-of-contents ul li {
    list-style-type: none;
  }

  .table-of-contents ul li::before {
    color: #ccc;
    content: "#";
    display: inline-block;
    margin-right: 0.5rem;
  }

  .table-of-contents a:hover {
    color: #20a8ea;
  }

  .table-of-contents a {
    color: inherit;
    text-decoration: none;
  }
`;

export default postTableOfContents;
